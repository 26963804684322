/**
 * Process the injected assets for display in the grid
 * @param {Array} injectedAssets assets being rendered to the grid
 * @returns {Array}
 */
export function getProductGridItemGridSlotsAssets(injectedAssets) {
  // Notes: though in json this is set to be an array, will only pass the container name of the first element of the array as ProductBrowser is expecting a string for gridSlotAsset param to be used in UseAsset()
  return injectedAssets?.length
    ? injectedAssets.map((asset) => {
        const assetObj = {
          container: asset.container,
          mobile_container:
            asset.containerMobile ||
            asset.backup_container ||
            asset.mobile_container,
        };
        if (asset.position && !isNaN(asset.position)) {
          assetObj.position = asset.position;
        }
        if (asset.row && !isNaN(asset.row)) {
          assetObj.row = asset.row;
        }
        if (asset.column && !isNaN(asset.column)) {
          assetObj.column = asset.column;
        }
        if (asset.size && !isNaN(asset.size)) {
          assetObj.size = asset.size;
        }
        return assetObj;
      })
    : null;
}
