export const getCategoryPath = (routeInfo) => {
  let categoryPath = '';

  if (routeInfo.isAlias) {
    categoryPath = routeInfo.targetUrlPath;
  } else {
    categoryPath = routeInfo.canonicalPath;
  }

  return categoryPath;
};
