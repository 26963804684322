import { buildProductDimensionsQueryFragment } from '~/grid/utils/getProductDimensionsQuery';
import { getProductSource } from '~/grid/utils/productSource';
import { pageSize } from '~/shared/constants/productGrid';
import {
  DEFAULT_GRID_ES_QUERY_FIELDS,
  DEFAULT_GRID_PRODUCT_FIELDS,
} from '~/shared/constants/products';
import { getCategoryPath } from '~/shared/utils/getCategoryPath';
import { modifyFiltersForLastChance } from '~/shared/utils/modifyFiltersForLastChance';
import {
  parseGqlProductsToV2,
  transformDimensions,
} from '~/techstyle-shared/react-graphql';
import { loadProducts } from '~/techstyle-shared/react-products';

import { getProductGridQueryParameters } from '../../grid/utils/getProductGridQueryParameters';
import { getBaseProductsQuery } from '../queries/grid';

import { getProductGridItemBoostedIds } from './getProductGridItemBoostedIds';
import { getProductGridItemCategoryNavSettings } from './getProductGridItemCategoryNavSettings';
import { getProductGridItemFiltersAndSortOptions } from './getProductGridItemFiltersAndSortOptions';
import { getProductGridItemGridSlotsAssets } from './getProductGridItemGridSlotsAssets';

export async function getProductGridItem({
  query,
  node,
  gender,
  genderFilterString,
  customer,
  membership,
  tld,
  categoryNav = {},
  rewriteSort,
  displayRetailPrice,
  genderAutoFilterEnabled,
  gridSortFeatureData,
  productSource,
  routeInfo,
  index,
  dispatch,
  isTabGrid,
  apolloClient,
  isGqlProducts = false,
  algorithmConfig,
  automaticSizeFiltering,
  disposition,
}) {
  let categoryPath = routeInfo ? getCategoryPath(routeInfo) : {};
  if (process.browser) {
    categoryPath = new URL(window.location);
  }

  const shouldUseSharedFilters =
    node.customFields?.shouldUseSharedFilters || false;
  const label = node.label;
  const shouldShowColorsLink = node.customFields?.shouldShowColorsLink || false;
  const shouldShowGridReviews =
    node.customFields?.shouldShowGridReviews || false;
  const hideSideBar = node.customFields?.hideSideBar ?? false;
  const hideHeader = node.customFields?.hideHeader ?? false;
  const isFiltersDisabled = node.customFields?.isFiltersDisabled ?? false;
  const imageType = node.customFields?.imageType;
  const gridCTA = (node.gridCTA || node.hasGridCta) ?? {};
  const gridColumns = (node.customFields.gridColumns || node.gridColumns) ?? {};
  const breakpoints =
    (node.customFields.breakpoints || node.breakpoints) ?? null;

  const boostedIds = await getProductGridItemBoostedIds({
    query,
  });

  const gridSlotsAssets = getProductGridItemGridSlotsAssets(
    node?.injectedAssets
  );

  const {
    activeCategory,
    breadcrumbs,
    shouldShowCategoryHeading,
    shouldShowCategoryDropdown,
  } = getProductGridItemCategoryNavSettings({ node, routeInfo, categoryNav });

  productSource =
    getProductSource({
      breadcrumbs: breadcrumbs,
      productSourceFromRoute: node.productSource
        ? node.productSource
        : productSource,
      urlPath: categoryPath,
    }) ?? productSource;

  const {
    initialSortOption,
    sortOptions,
    baseFilters,
    initialFilters,
    filters,
    autoAppliedInitialFilters,
  } = await getProductGridItemFiltersAndSortOptions({
    node,
    rewriteSort,
    gridSortFeatureData,
    displayRetailPrice,
    query,
    customer,
    gender,
    categoryPath,
    genderAutoFilterEnabled,
    genderFilterString,
    membership,
    tld,
    isTabGrid,
    automaticSizeFiltering,
    disposition,
  });

  modifyFiltersForLastChance({
    filters,
    alwaysShowLastChance: node?.showLastChance || false,
  });

  const { productRequestParams, initialProducts } = await loadInitialProducts({
    initialSortOption,
    displayRetailPrice,
    boostedIds,
    filters,
    productSource,
    dispatch,
    algorithmConfig,
    isGqlProducts,
    apolloClient,
  });

  return {
    ...node,
    isFiltersDisabled,
    productSource,
    activeCategory,
    breadcrumbs,
    breakpoints,
    label,
    primaryFplId: null,
    profileSizes: {},
    gridColumns,
    gridSlotsAsset: gridSlotsAssets,
    shouldShowCategoryHeading,
    shouldShowCategoryDropdown,
    shouldUseSharedFilters,
    shouldShowColorsLink,
    shouldShowGridReviews,
    browserKeyId: getProductBrowserKey(),
    hideSideBar,
    hideHeader,
    imageType,
    gridCTA,
    nodeIndex: index,
    baseFilters,
    initialFilters,
    initialSortOption,
    initialProducts,
    sortOptions,
    productRequestParams,
    autoAppliedInitialFilters,
  };
}

export function getProductBrowserKey() {
  const min = 0;
  const max = 100000;
  return Math.floor(Math.random() * (max - min) + min);
}

export async function loadInitialProducts({
  initialSortOption,
  displayRetailPrice,
  boostedIds,
  filters,
  productSource,
  dispatch,
  algorithmConfig,
  isGqlProducts = false,
  apolloClient,
}) {
  const productRequestParams = [
    {
      page: 1,
      size: pageSize,
      algorithmConfig,
      sort: initialSortOption?.sort,
      retailDiscounting: displayRetailPrice,
      boostedIds,
      ...filters,
      // NOTE: restricts product fields returned in order to
      // decrease payload size from API
      productFields: DEFAULT_GRID_PRODUCT_FIELDS,
      fields: DEFAULT_GRID_ES_QUERY_FIELDS,
    },
    { apiVersion: 2, productSource },
  ];

  let initialProducts;

  try {
    // TODO: constructor, this is where you do initial page load and should query just base data
    if (isGqlProducts) {
      const { variables } = getProductGridQueryParameters(
        productRequestParams[0]
      );

      const initialProductsResponse = await apolloClient
        .query({
          context: { clientName: 'supergraph' },
          query: getBaseProductsQuery(
            buildProductDimensionsQueryFragment(productRequestParams[0])
          ),
          variables,
        })
        .then(({ data: { productListing, productDimensions } }) => {
          const aggregations = productDimensions?.dimensions
            ? transformDimensions(productDimensions.dimensions)
            : [];

          const parsedProducts = productListing?.products
            ? parseGqlProductsToV2(productListing?.products)
            : [];

          return {
            payload: {
              products: parsedProducts,
              productDimensions,
              aggregations,
              total: productListing?.total?.value ?? 0,
              page: 1,
              pageSize,
            },
          };
        });

      initialProducts = initialProductsResponse?.payload;
    } else {
      const initialProductsResponse = await dispatch(
        loadProducts(...productRequestParams)
      );
      initialProducts = initialProductsResponse?.payload;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('QUERY FOR PRODUCTS FAILED', e);
  }

  return { productRequestParams, initialProducts };
}
