import { Builder } from '@builder.io/react';

import { isFabletics } from '~/shared/utils/brandNameHelper';
import logger from '~/shared/utils/logger';

import { autoResolveBuilderLocale } from './autoResolveBuilderLocale';
import { AdIdTypes, getAlgoConfig } from './getAlgoConfig';
import { getProductGridItem } from './getProductGridItem';

const debug = logger.extend('getBuilderCollectionContent');

const MembershipBrandIds = {
  fabletics: [1],
  yitty: [2],
  both: [],
};

const appendFpl = (fplArray, fplInput) => {
  fplArray.push({
    id: fplArray.length,
    value: fplInput,
  });
};

const mapAggs = (array, key, propName = 'value', options = {}) =>
  array
    ?.filter((value) => !!value[key])
    ?.map((value, i) => ({
      id: i,
      [propName]: value[key],
      ...options,
    })) ?? [];

const disabledCollection = {
  collectionContent: [],
  isCollectionDisabled: true,
};

export const getBuilderCollectionContent = async ({
  customer,
  categoryNav,
  displayRetailPrice,
  gender,
  genderAutoFilterEnabled,
  genderFilterString,
  membership,
  options,
  region,
  tld,
  dispatch,
  routeInfo,
  query,
  isTabGrid,
  apolloClient,
  customerDetails,
  disposition,
  automaticSizeFiltering,
}) => {
  const {
    brand,
    algoOptions,
    excludeFpls,
    excludeCategoryIds,
    excludeTagIds,
    filters,
    fontVariant,
    fplGlobalCodes,
    gridAssetContainer,
    gridAssetContainerMobile,
    gridCTA,
    gridGender,
    gridSortFeature,
    gridStyleGuide,
    imageType,
    includeOutOfStock,
    isCarouselV2,
    showLastChance,
    label,
    maxItemCount,
    productCategoryIds,
    pdpLinkOverride,
    productSource,
    productTypeIds,
    sideNav,
    sort,
    sortList,
    tagIds,
    excludePreorderProducts,
    developerSettings,
    /**
     * Sometimes builder.io doesn't auto resolve localized grid data quickly enough while previewing.
     * For redundancy and to prevent erroring, we also auto resolve localized grid data.
     */
  } = Builder.isPreviewing
    ? autoResolveBuilderLocale('Default', options)
    : options;

  const hideSideBar =
    (!filters || !filters.length) && !sideNav?.shouldShowCategoryDropdown;

  const gridNode = {
    __component: 'node.grid',
    aggsProductCategoryIds: [],
    algoOptions,
    availableQtyMin: null,
    boostedFplId: null,
    categoryIds: mapAggs(productCategoryIds, 'productCategoryId'),
    categoryNavContainer: sideNav?.categoryNavContainer,
    initialProductsCachettl: developerSettings?.initialProductsCachettl,
    // TODO: constructor, you need something to declare that this is a constructor grid(probably a prescriptive grid component)
    isGqlProducts: developerSettings?.isGqlProducts,
    customFields: {
      aggregations: mapAggs(filters, 'filter', 'field', { size: 50 }),
      hideSideBar,
      imageType,
      membershipBrandIds: MembershipBrandIds[brand],
      shouldShowCategoryDropdown: !!sideNav?.shouldShowCategoryDropdown,
      shouldShowCategoryHeading: !!sideNav?.shouldShowCategoryHeading,
      excludePreorderProducts,
      gridGender,
    },
    excludeCategoryIds: mapAggs(excludeCategoryIds, 'excludeCategoryId'),
    excludeFpls: mapAggs(excludeFpls, 'excludeFpl'),
    excludeTagIds: mapAggs(excludeTagIds, 'excludeTagId'),
    fontVariant,
    fplCodes: mapAggs(fplGlobalCodes, 'fplGlobalCode'),
    fpls: [],
    gridCTA,
    gridSortFeature,
    gridStyleGuide: {
      shouldShowStyleGuide: !!gridStyleGuide,
      ...gridStyleGuide,
    },
    id: 2,
    size: null,
    hideSortOption: sortList?.length <= 1,
    productSource,
    sort,
    sortList,
    includeOutOfStock: !!includeOutOfStock,
    injectedAssets: [],
    isCarousel: isCarouselV2,
    showLastChance: showLastChance || false,
    label,
    loadMore: null,
    maxItemCount,
    pdpLinkOverride,
    productTypeIds: mapAggs(productTypeIds, 'productTypeId'),
    tagIds: mapAggs(tagIds, 'tagId'),
  };

  if (gridAssetContainer || gridAssetContainerMobile) {
    gridNode.injectedAssets.push({
      container: gridAssetContainer,
      backup_container: gridAssetContainerMobile,
    });
  }

  let browserRouteInfo;
  if (process.browser) {
    const currentUrl = new URL(window.location);
    browserRouteInfo = {
      canonicalPath: currentUrl?.pathname,
    };
  }

  let algorithmConfig;
  if (gridNode?.algoOptions && gridNode?.algoOptions?.esIndex) {
    algorithmConfig = getAlgoConfig({
      algoOptions: gridNode?.algoOptions,
      algoConfigContext: {
        customerDetails,
        membership,
        isScrubs: disposition?.experience === 'scrubs',
        isFabletics: isFabletics(),
      },
    });

    if (gridNode?.customFields) {
      gridNode.customFields.algorithmConfig = algorithmConfig;
    }

    debug('algorithmConfig', {
      algoOptions: gridNode.algoOptions,
      algorithmConfig,
    });
    const { isAlgoDisabled, fallbackFpl, algoDisabledReason } = algorithmConfig;
    if (isAlgoDisabled) {
      if (!fallbackFpl) {
        return {
          ...disabledCollection,
          collectionDisabledReason: algoDisabledReason,
          algorithmConfig,
        };
      }

      // if we've disabled the algo, but have a fallback, we'll use the fallback fpl directly in the v2 call
      appendFpl(gridNode.fplCodes, fallbackFpl);
      // now we nullify this so we don't use it in the v2 call
      algorithmConfig = null;
    }
  }

  const collectionContent = await getProductGridItem({
    node: gridNode,
    customer,
    membership,
    region,
    tld,
    genderAutoFilterEnabled,
    gender,
    genderFilterString,
    displayRetailPrice,
    categoryNav,
    dispatch,
    routeInfo: routeInfo || browserRouteInfo,
    query,
    isTabGrid,
    isGqlProducts: gridNode?.isGqlProducts,
    apolloClient,
    disposition,
    algorithmConfig,
    automaticSizeFiltering,
  });

  if (
    algorithmConfig?.algorithmQueryOptions?.type === AdIdTypes.AD_ID &&
    !collectionContent?.debugData?.algorithmTrace?.action ===
      'halt:thresholdNotMet'
  ) {
    return {
      ...disabledCollection,
      collectionDisabledReason: 'Algorithm threshold not met.',
      algorithmConfig,
    };
  }

  return collectionContent;
};
