import config from 'config';

import { slugify } from '~/techstyle-shared/react-components';

function createProductSource({ breadcrumbs, urlPath }) {
  const prefix = config.get('public.brand.psourcePrefix') || '';
  if (!breadcrumbs) {
    return urlPath ? prefix + slugify(urlPath) : null;
  }
  const categoryLabels = breadcrumbs.map(({ label }) => slugify(label));
  return prefix + categoryLabels.join('_');
}

export function getProductSource({
  breadcrumbs,
  productSourceFromRoute,
  urlPath,
}) {
  if (productSourceFromRoute) {
    return productSourceFromRoute;
  }
  return createProductSource({ breadcrumbs, urlPath });
}
