import { getLocalStorageItemWithExpiry } from '~/shared/utils/localStorageHelper';

// function to retrieve the boosted ids for displaying the grid data
export async function getProductGridItemBoostedIds({ query }) {
  // didn't write this, but it looks like there is an option to pass in an
  // mpid or mpid_list parameter which will boost those product ids to the
  // top of the result set

  const persistedMpid = getLocalStorageItemWithExpiry('mpid');
  const persistedMpidList = getLocalStorageItemWithExpiry('mpid_list');
  const adReferredMpid = query?.mpid || persistedMpid;
  const adReferredMpidList = query?.mpid_list || persistedMpidList;
  let boostedIds = adReferredMpid ? [parseInt(adReferredMpid)] : [];
  boostedIds = adReferredMpidList
    ? [
        ...adReferredMpidList.split(',').map((mpid) => parseInt(mpid)),
        ...boostedIds,
      ]
    : boostedIds;
  return boostedIds;
}
